import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {RouterLink} from '@angular/router'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {filter, switchMap, tap} from 'rxjs/operators'
import {
  ApplicationService,
  ApplicationState,
  ApplicationViews
} from '../../services/application.service'
import {ConfigService, LoggedInState} from '../../services/config.service'
import {User} from '../../services/types'
import {UserService} from '../../services/user.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, AsyncPipe, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, LogoComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * When the header is ready to be viewed
   */
  public ready = false

  /**
   * We have to export this to the template;
   */
  public applicationViews = ApplicationViews

  /**
   * View name to display,
   */
  public viewName: string

  /**
   * The currently logged in user.
   */
  public user: User

  /**
   * Tells if admin functions are available.
   */
  public isAdmin = false

  /**
   * Tells if Tester functions are available.
   */
  public isTester = false


  /**
   * Should we show the bulk import or not?
   */
  public bulkView = false

  /**
   * Should we show the file view or not?
   */
  public fileView = false

  /**
   * Subscribe to application state changes.
   */
  private application$: Subscription = new Subscription()

  /**
   * Subscribe to logged in state changes.
   */
  private login$: Subscription = new Subscription()

  /**
   * The constructor
   *
   * @param configService - To log out.
   * @param userService - To fetch current user.
   * @param applicationService - The application service to update with state.
   */
  constructor(
    public configService: ConfigService,
    public userService: UserService,
    public applicationService: ApplicationService
  ) {
  }

  /**
   *
   */
  ngOnInit() {
    this.login$ = this.configService.logInState$
      .pipe(
        tap((state: LoggedInState) => {
          if (!state.loggedIn) {
            delete this.user
          }
        }),
        filter(() => !this.user), // If we have a user we skip
        switchMap(() => this.applicationService.applicationState),
        switchMap((state: ApplicationState) => {
          if (state && state.view) {
            this.viewName = ['Kontor', 'Kredit', 'Internstöd', 'Kundstöd'][state.view]
            this.bulkView = state.view === ApplicationViews.CREDIT || state.view === ApplicationViews.INTERNAL_SUPPORT
            this.fileView = state.view === ApplicationViews.CREDIT ||
              state.view === ApplicationViews.INTERNAL_SUPPORT ||
              state.view === ApplicationViews.CUSTOMER_SUPPORT

            setTimeout(() => {
              this.ready = true
            }, 1)
          }
          return this.userService.currentUser$
        })
      )
      .subscribe({
        next: (user: User) => {
          if (user) {
            this.user = user
            this.isAdmin = this.user.roles.indexOf('fmAdmin') !== -1
            this.isTester = this.user.roles.indexOf('tester') !== -1
          }
        }
      })
  }

  /**
   * Remember to unsubscribe to avoid leakage.
   */
  ngOnDestroy() {
    this.application$.unsubscribe()
    this.login$.unsubscribe()
  }
}
