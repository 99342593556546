<div class="header">
  <spb-logo [routePath]="['base']" title="Aktbevakning"></spb-logo>
  @if (ready && (userService.currentUser$ | async)) {
    <div class="menu">
      @if (fileView) {
        <div class="pointer" [routerLink]="['/base']">Aktvy</div>
      }
      @if (bulkView) {
        <div class="pointer" [routerLink]="['/base', 'bulk']">Ank. reg.</div>
      }
      <div class="pointer" [routerLink]="['/base', 'reports']">Rapporter</div>
    </div>
    <div [style.flex-grow]="1"></div>

    @if ((isAdmin || isTester) && (userService.currentUser$ | async); as user) {
      <div class="view-name">
        <span>{{ user.name }} ({{ viewName }})</span>
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    }
  }
</div>
<!-- Add other navigations here -->
<mat-menu #menu="matMenu">
  @if (isAdmin || isTester) {
    <button [matMenuTriggerFor]="views" mat-menu-item>Vy</button>
  }
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>

<mat-menu #views="matMenu">
  <button
    (click)="applicationService.selectView(applicationViews.INTERNAL_SUPPORT)"
    mat-menu-item>
    Internstöd
  </button>
  <button (click)="applicationService.selectView(applicationViews.CREDIT)"
          mat-menu-item>
    Kredit
  </button>
  <button
    (click)="applicationService.selectView(applicationViews.CUSTOMER_SUPPORT)"
    mat-menu-item>
    Kundstöd
  </button>
  <button (click)="applicationService.selectView(applicationViews.OFFICE)"
          mat-menu-item>
    Kontor
  </button>
</mat-menu>
