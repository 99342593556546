import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {UserResolver} from './application/user.resolver'

export const routes: Routes = [
  {
    path: 'base',
    loadChildren: () => import('./base/base.routes'),
    canActivate: [authGuard],
    canMatch: [authGuard],
    resolve: {
      users: UserResolver
    }
  },
  {
    path: 'home',
    loadComponent: () => import('./home/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
]